@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: black;
}

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(36, 36, 36);
  background-image: radial-gradient(circle, rgba(36, 36, 36, 1) 0%, rgba(16, 16, 16, 1) 100%);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.glassmorph {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

body {
  position: relative;
}

body::before {
  content: ' ';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.02;
  background-image: url('assets/background.webp');
  background-position: center;
  background-size: contain;
  background-color: rgba(0, 0, 0, 0.1);
}